import axios from "@/services/axiosMainInstance";
import {
  GetEventDatesPayloadType,
  GetEventDatesResponseType,
  SuccessGetEventDatesResponseType,
} from "@/types/api/events";
import { API_COMMON_STATUS, API_RESPONSE_MESSAGES } from "@/helpers/api-helpers";

const getEventDates = async ({
  email,
  password,
  eventId,
}: GetEventDatesPayloadType) => {
  try {
    const response = await axios.get<SuccessGetEventDatesResponseType>(
      `/api/kiosk/event/date/list?email=${email}&password=${password}&eventId=${eventId}`
    );
    let formattedResponse: GetEventDatesResponseType;
    switch (response.status) {
      case API_COMMON_STATUS.SUCCESS: {
        formattedResponse = {
          status: response.status,
          data: response.data,
        };
        break;
      }
      case API_COMMON_STATUS.UNAUTHORIZED: {
        formattedResponse = {
          status: response.status,
          data: response.data,
          message: API_RESPONSE_MESSAGES.UNAUTHORIZED,
        };
        break;
      }
      default: {
        formattedResponse = {
          status: response.status,
          data: response.data,
          message: API_RESPONSE_MESSAGES.UNKNOWN_ERROR,
        };
      }
    }

    return formattedResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getEventDates;
