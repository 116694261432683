import { useState, useEffect } from "react";
import getEvents from "@/services/Events/getEvents";
import { UseEventsListProps } from "@/types/api/events";
import { API_COMMON_STATUS } from "@/helpers/api-helpers";
import { SuccessGetEventsResponseType } from "@/types/api/events";

const useEventsList = ({ payload }: UseEventsListProps) => {
  const [eventsList, setEventsList] = useState<SuccessGetEventsResponseType>({
    events: [],
  });

  useEffect(() => {
    getEvents(payload)
      .then((response) => {
        if (API_COMMON_STATUS.SUCCESS === response.status) {
          setEventsList(response.data);
        } else {
          console.log("getEvents error", response);
        }
      })
      .catch((error) => {
        console.error(error, "catch error for getEvents");
      });
  }, [payload]);

  return eventsList;
};

export default useEventsList;
