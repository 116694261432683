import axios from "@/services/axiosMainInstance";
import {
  GetAgentACLPayloadType,
  GetAgentACLResponseType,
  SuccessGetAgentACLResponseType,
} from "@/types/api/acl";
import { API_COMMON_STATUS, API_RESPONSE_MESSAGES } from "@/helpers/api-helpers";

const getAgentACL = async ({
  email,
  password,
  eventId,
  eventDateId,
}: GetAgentACLPayloadType) => {
  try {
    const response = await axios.get<SuccessGetAgentACLResponseType>(
      `/api/kiosk/acl/list?email=${email}&password=${password}&eventId=${eventId}&eventDateId=${eventDateId}`
    );
    let formattedResponse: GetAgentACLResponseType;

    switch (response.status) {
      case API_COMMON_STATUS.SUCCESS: {
        formattedResponse = {
          status: response.status,
          data: response.data,
        };
        break;
      }
      case API_COMMON_STATUS.UNAUTHORIZED: {
        formattedResponse = {
          status: response.status,
          data: response.data,
          message: API_RESPONSE_MESSAGES.UNAUTHORIZED,
        };
        break;
      }
      case API_COMMON_STATUS.NOT_FOUND: {
        formattedResponse = {
          status: response.status,
          data: response.data,
          message: API_RESPONSE_MESSAGES.NOT_FOUND,
        };
        break;
      }
      default: {
        formattedResponse = {
          status: response.status,
          data: response.data,
          message: API_RESPONSE_MESSAGES.UNKNOWN_ERROR,
        };
      }
    }

    return formattedResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getAgentACL;
