import { useState, useEffect } from "react";
import getAgentACL from "@/services/ACL/getAgentACL";
import useToast from "@/hooks/useToast";
import {useTranslation} from "@/i18n/client";
import {
  UseAgentACLListProps,
  SuccessGetAgentACLResponseType,
} from "@/types/api/acl";
import { API_COMMON_STATUS } from "@/helpers/api-helpers";
import { useParams } from "next/navigation";

const useAgentACLList = ({
  payload,
  updateSelectionState,
}: UseAgentACLListProps) => {
  const [agentACLList, setAgentACLList] =
    useState<SuccessGetAgentACLResponseType>({ acls: [] });
  const { toast } = useToast();
  const {lng} = useParams();
  const { t:translate  } = useTranslation(lng,"scan");


  useEffect(() => {
    if (payload.eventId && payload.eventDateId) {
      getAgentACL(payload)
        .then((response) => {
          if (API_COMMON_STATUS.SUCCESS === response.status) {
            setAgentACLList(response.data);
          } else if (API_COMMON_STATUS.NOT_FOUND === response.status) {
            toast.error(translate("pages.event-selection.no-acl-error"), {
              hideProgressBar: true,
            });
            updateSelectionState({
              event: "",
              eventDate: "",
              acl: "",
            });
          }
        })
        .catch((error) => {
          console.error(error, "catch error for getAgentACL");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return agentACLList;
};

export default useAgentACLList;
