export const API_COMMON_STATUS = Object.freeze({
  SUCCESS: 200,
  FAILED: 500,
  UNAUTHORIZED: 401,
  UNAUTHENTICATED: 403,
  NOT_FOUND: 404,
});

export const API_RESPONSE_MESSAGES = Object.freeze({
  SUCCESS: "SUCCESS",
  UNAUTHENTICATED: "UNAUTHENTICATED",
  UNAUTHORIZED: "UNAUTHORIZED",
  UNKNOWN_ERROR: "UNKNOWN ERROR",
  NOT_FOUND: "NOT FOUND",
});
