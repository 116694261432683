import { useState, useEffect } from "react";
import getEventDates from "@/services/Events/getEventDates";
import { useEventDatesListProps } from "@/types/api/events";
import { API_COMMON_STATUS } from "@/helpers/api-helpers";
import { SuccessGetEventDatesResponseType } from "@/types/api/events";

const useEventDatesList = ({ payload }: useEventDatesListProps) => {
  const [eventDatesList, setEventDatesList] =
    useState<SuccessGetEventDatesResponseType>({ eventDates: [] });

  useEffect(() => {
    if (payload.eventId) {
      getEventDates(payload)
        .then((response) => {
          if (API_COMMON_STATUS.SUCCESS === response.status) {
            setEventDatesList(response.data);
          } else {
            console.log("getEventDates error", response);
          }
        })
        .catch((error) => {
          console.error(error, "catch error for getEventDates");
        });
    }
  }, [payload]);

  return eventDatesList;
};

export default useEventDatesList;
