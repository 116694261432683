import axios from "@/services/axiosMainInstance";
import {
  GetEventsPayloadType,
  GetEventsResponseType,
  SuccessGetEventsResponseType,
} from "@/types/api/events";
import { API_COMMON_STATUS, API_RESPONSE_MESSAGES } from "@/helpers/api-helpers";

const getEvents = async ({ email, password }: GetEventsPayloadType) => {
  try {
    const response = await axios.get<SuccessGetEventsResponseType>(
      `/api/kiosk/event/list?email=${email}&password=${password}`
    );
    let formattedResponse: GetEventsResponseType;

    switch (response.status) {
      case API_COMMON_STATUS.SUCCESS: {
        formattedResponse = {
          status: response.status,
          data: response.data,
        };
        break;
      }
      case API_COMMON_STATUS.UNAUTHORIZED: {
        formattedResponse = {
          status: response.status,
          data: response.data,
          message: API_RESPONSE_MESSAGES.UNAUTHORIZED,
        };
        break;
      }
      default: {
        formattedResponse = {
          status: response.status,
          data: response.data,
          message: API_RESPONSE_MESSAGES.UNKNOWN_ERROR,
        };
      }
    }

    return formattedResponse;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default getEvents;
