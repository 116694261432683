"use client";
import { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Select, { SelectChangeEvent } from "@/components/Select";
import Button from "@/components/Button";
import useEventsList from "@/hooks/APIHooks/useEventsList";
import useEventDatesList from "@/hooks/APIHooks/useEventDatesList";
import useAgentACLList from "@/hooks/APIHooks/useAgentACLList";
import { useGlobalContext } from "@/contexts/GlobalContext";
import { useRouter } from "next/navigation";
import { setStorageItem } from "@/helpers/general-helpers";
import { STORAGE_KEYS } from "@/config/kiosk";
import {useTranslation} from "@/i18n/client";

const EventsSelection = ({ params: { lng } }) => {
  const [{ email, password }] = useGlobalContext();
  const [{ event, eventDate, acl }, setSelectionState] = useState({
    event: "",
    eventDate: "",
    acl: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [
    { eventErrorMessage, eventDateErrorMessage, aclErrorMessage },
    setSelectionErrorMessages,
  ] = useState({
    eventErrorMessage: "",
    eventDateErrorMessage: "",
    aclErrorMessage: "",
  });
  const { push, } = useRouter();
  const { t :translate } = useTranslation(lng,"sign-in");


  const getEventsPayload = useMemo(
    () => ({
      email,
      password,
    }),
    [email, password]
  );

  const getEventDatesPayload = useMemo(
    () => ({
      email,
      password,
      eventId: event,
    }),
    [email, password, event]
  );

  const getEventACLPayload = useMemo(
    () => ({
      email,
      password,
      eventId: event,
      eventDateId: eventDate,
    }),
    [email, password, event, eventDate]
  );

  const { events: eventsList } = useEventsList({
    payload: getEventsPayload,
  });

  const { eventDates: eventDatesList } = useEventDatesList({
    payload: getEventDatesPayload,
  });

  const { acls: aclList } = useAgentACLList({
    payload: getEventACLPayload,
    updateSelectionState: setSelectionState,
  });

  const changeHandler = (event: SelectChangeEvent<unknown>) => {
    if (event.target.name === "event") {
      const targetEvent = eventsList.find(
        (eventObj) => String(eventObj.id) === (event.target.value as string)
      );
      setStorageItem(STORAGE_KEYS.eventName, targetEvent?.name);
    }

    setSelectionState((prevSelectionState) => ({
      ...prevSelectionState,
      [event.target.name]: event.target.value as string,
    }));
  };

  const selectionSubmitHandler = () => {
    setIsSubmitted(true);
    if (event && eventDate && acl) {
      push(`check-in/${event}/${eventDate}/${acl}/scan`);
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      const errorsMessages = {
        eventErrorMessage: event
          ? ""
          : translate("pages.event-selection.required-event"),
        eventDateErrorMessage: eventDate
          ? ""
          : translate("pages.event-selection.required-event-date"),
        aclErrorMessage: acl
          ? ""
          : translate("pages.event-selection.required-acl"),
      };
      setSelectionErrorMessages(errorsMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, eventDate, acl, isSubmitted]);

  useEffect(() => {
    if (eventsList && !!eventsList.find((event) => event.id === 8663)) {
      if (event && eventDate && acl) {
        push(`check-in/${event}/${eventDate}/${acl}/scan`);
      }

      if (!event && eventsList) {
        setSelectionState((prevSelectionState) => ({
          ...prevSelectionState,
          event: `${eventsList[0]?.id || ""}`,
        }));
      } else if (!eventDate && eventDatesList) {
        setSelectionState((prevSelectionState) => ({
          ...prevSelectionState,
          eventDate: `${eventDatesList[0]?.id || ""}`,
        }));
      } else if (!acl && aclList) {
        setSelectionState((prevSelectionState) => ({
          ...prevSelectionState,
          acl: `${aclList[0]?.id || ""}`,
        }));
      }
    }
  }, [event, eventsList, eventDate, eventDatesList, acl, aclList, push]);

  const selectGridWidth = {
    minWidth: {
      xs: 1,
      sm: 0.5,
    },
    maxWidth: 1,
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      sx={{ minHeight: "calc(100vh - 84px)" }}
    >
      <Grid item sx={selectGridWidth}>
        <Select
          size="medium"
          label={translate("pages.event-selection.event")}
          fullWidth
          name="event"
          required
          error={!!eventErrorMessage}
          helperText={eventErrorMessage}
          value={event}
          onChange={changeHandler}
          options={eventsList.map((event) => ({
            value: String(event.id),
            label: event.name,
          }))}
        />
      </Grid>
      <Grid item sx={selectGridWidth}>
        <Select
          size="medium"
          label={translate("pages.event-selection.event-dates")}
          fullWidth
          name="eventDate"
          disabled={!event}
          required
          error={!!eventDateErrorMessage}
          helperText={eventDateErrorMessage}
          value={eventDate}
          onChange={changeHandler}
          options={eventDatesList.map((eventDate) => ({
            value: String(eventDate.id),
            label: `${new Date(
              eventDate.startDate
            ).toLocaleString()} - ${new Date(
              eventDate.endDate
            ).toLocaleString()}`,
          }))}
        />
      </Grid>
      <Grid item sx={selectGridWidth}>
        <Select
          size="medium"
          label={translate("pages.event-selection.acl")}
          fullWidth
          name="acl"
          disabled={!event}
          required
          error={!!aclErrorMessage}
          helperText={aclErrorMessage}
          value={acl}
          onChange={changeHandler}
          options={aclList.map((acl) => ({
            value: String(acl.id),
            label: acl.title,
          }))}
        />
      </Grid>
      <Button onClick={selectionSubmitHandler}>
        {translate("general.submit")}
      </Button>
    </Grid>
  );
};

export default EventsSelection;
