import axios from "axios";
import { API_END_POINT,getApiEndpoint ,KIOSK_GENERAL_DOMAIN,KIOSK_SETTINGS_API} from "@/config/kiosk";



const axiosMainInstance = axios.create({
  baseURL: getApiEndpoint() || API_END_POINT,
  validateStatus: (status) => status < 500,
});

export const axiosSettingsInstance = axios.create({
  baseURL: KIOSK_SETTINGS_API,
  validateStatus: (status) => status < 500,
});

export const axiosSajilniInstance = axios.create({
  baseURL: KIOSK_GENERAL_DOMAIN,
  validateStatus: (status) => status < 500,
});

export const setAxiosBaseURL = (newURL) => {
  axiosMainInstance.defaults.baseURL = newURL;
};

export default axiosMainInstance;
